import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import CardEventArtChat from './CardEventArtChat';
import LinkInput from './LinkInput';
import TextUserPair from './TextUserPair';
import SelectableTags from './SelectableTags';
import ShareButton from './ShareButton';
import { Link } from 'react-router-dom';

const PersonalArtEvents = ({eventType, userId}) => {
  const myUser = useContext(MyUserContext);
  const [finishedInput, setFinishedInput] = useState("");
  const [finishedEvents, setFinishedEvents] = useState(null);

  const [hasAccess, setHasAccess] = useState(true);

  const [artNameTerm, setArtNameTerm] = useState('');

  const { pathUserId } = useParams();
  const effectiveUserId = () => {
    // UserID from props or path param.
    let effectiveUserId = userId;
    if (!effectiveUserId) {
      effectiveUserId = pathUserId;
    }
    return effectiveUserId;
  }

  const alreadySelectedTagIds = new URLSearchParams(window.location.search).getAll('tags');

  const alreadySelectedTagIdsParam = alreadySelectedTagIds.map((t) => { return "tags="+t}).join("&");
  const initEventsFetchUrl = `/artEvents/${effectiveUserId()}/${eventType}?${alreadySelectedTagIdsParam}`;
  const [nextUrl, setNextUrl] = useState(initEventsFetchUrl);
  const [loading, setLoading] = useState(false);
  const observer = useRef();

  const searchInputRef = useRef(null);

  const [fetching, setFetching] = useState(false);

  const [curUser, setCurUser] = useState(null); // some other user
  const fetchCurUser = () => {
    axiosInstance
    .get(`/users/${effectiveUserId()}`)
    .then((response) => {
      setCurUser(response.data);
    })
    .catch((error) => {
      console.error(error);      
    });
  }

  const [relevantTags, setRelevantTags] = useState(null);
  const fetchRelevantTags = (alreadySetTagIds=[]) => {
    const tagParams = alreadySetTagIds.map((n) => { return "alreadySetTags="+n; }).join("&");
    axiosInstance
    .get(`/relevant/tags?userId=${effectiveUserId()}&type=${eventType}&${tagParams}`)
    .then((response) => {
      setRelevantTags(response.data);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  const [unfinishedRecomsCount, setUnfinishedRecomsCount] = useState(null);
  const fetchUnfinishedRecomsCount = () => {
    axiosInstance
    .get('/recommendations/unfinished/count')
    .then((response) => {
      setUnfinishedRecomsCount(response.data.unfinished);
    })
    .catch((error) => {
      // console.error(error.message);
    })
  }

  const fetchFinishedEvents = (nextUrl, fromScratch = false) => {
    if (!nextUrl || loading) {
      return;
    }
    setLoading(true);
    axiosInstance
    .get(nextUrl)
    .then((response) => {
      if (finishedEvents === null || fromScratch) {
        setFinishedEvents(response.data.data);
      } else {
        setFinishedEvents(prevObjects => [...prevObjects, ...response.data.data]);
      }
      setNextUrl(response.data.nextPageUrl);
    })
    .catch((error) => {
      if (error.response) {
        // Server responded with a status other than 200 range
        if (error.response.status === 403 || error.response.status === 401) {
          // Handle 403 Forbidden
          setHasAccess(false);
          console.log("Setting has access to false");
        } else {
          console.error('Error: ', error.response.data);
        }
      } else {
        console.error(error);  
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleFinishedInputChange = (event) => {
    setFinishedInput(event.target.value);
  };
  const handleFinished = () => {
    axiosInstance
    .post(`/artEvents`, { wikiArticleLink: finishedInput, type: eventType })
    .then((response) => {
      if (finishedEvents === null) {
        setFinishedEvents(response.data);
      } else {
        setFinishedEvents(prevObjects => [response.data, ...prevObjects]);  
      }
      setFinishedInput("");
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const lastObjectRef = useCallback(node => {
    if (loading) return;

    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        fetchFinishedEvents(nextUrl);
      }
    });
    if (node) {
      observer.current.observe(node);
    }
  }, [loading, nextUrl, fetchFinishedEvents]);

  const curUserIsMe = () => {
    return (myUser && effectiveUserId() === myUser.myUserInfo.myUserId);
  }

  const isMobileDeviceExceptAndroid = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /iphone|ipad|ipod|windows phone|blackberry/i.test(userAgent);
  };

  const isFirefox = () => {
    return navigator.userAgent.toLowerCase().includes('firefox');
  };

  if (hasAccess && (finishedEvents === null || (curUser === null))) {
    if (fetching === false) {
      setFetching(true);
      fetchFinishedEvents(nextUrl);
      fetchUnfinishedRecomsCount();
      //if (!curUserIsMe()) {
        fetchCurUser();
      //}

      // const searchParams = new URLSearchParams(window.location.search);
      // const alreadySelectedTagNames = searchParams.getAll('tags');
      fetchRelevantTags(alreadySelectedTagIds);
    }
    return (
      <div></div>
    )
  } else {
    // setFetching(false); // Needed to comment to avoid re-render, wtf
  }

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  let buttonTextLocalizeKey = "addFinished";
  if (eventType === "like") {
    buttonTextLocalizeKey = "like";
  } else if (eventType === "todo") {
    buttonTextLocalizeKey = "addTodo";
  }

  // console.log("finishedEvents[0].event.chatId: " + finishedEvents[0].event.chatId);

  // Links to other types of events: start
  // TODO: move it to a separate user page component.
  const likesLink = `/users/${effectiveUserId()}/likes`;
  const classLikes = (eventType === "like" ? "underline" : "");

  const todosLink = `/users/${effectiveUserId()}/todos`;
  const classTodos = (eventType === "todo" ? "underline" : "");

  const finishedLink = `/users/${effectiveUserId()}/finished`;
  const classFinished = (eventType === "finished" ? "underline" : "");

  // Links to other types of events: end

  return (
    <div class="container mx-auto px-1 md:px-2 w-full lg:w-1/2">
      <div class="container mx-auto mt-4">
        {
          myUser && effectiveUserId() !== myUser.myUserInfo.myUserId &&
            <TextUserPair user={curUser} userId={effectiveUserId()} hideRecomInput={true}/>
        }        
      </div>
      {
      (hasAccess === false) ?
        <div class="flex justify-center text-center">
          {language === "ru" ? "Приватный аккаунт, подпишитесь на пользователя и дождитесь подтверждения" : "Account is private, subscribe to user and wait for approval"}
        </div> 
        :
          <>
            {
            eventType.includes('recommendation')
            &&
            <Link to={`/users`}>
              <div class="flex justify-center text-center font-bold text-blue-500 hover:text-blue-800">
                {language === "ru" ? "Все пользователи" : "All users"}
              </div>
            </Link>
            }

            {
            !myUser &&
            <div class="flex font-bold justify-center items-center mt-1 md:mt-2">
              <img class="rounded-full aspect-square max-w-8 max-h-8" src={curUser.avatarUrl}/> &nbsp; {curUser.userName} {/*alreadySelectedTagNames.length > 0 ? ", " + alreadySelectedTagNames.map((t) => { return "#"+t}).join(" ") : ""*/}
            </div>
            }

            {
            (finishedEvents && finishedEvents.length == 0) && artNameTerm === '' && !loading &&
              <div class="mt-5 justify-center text-center">
                {
                language === "ru"
                ?                  
                  (myUser && effectiveUserId() === myUser.myUserInfo.myUserId)
                  ?
                    eventType.includes('recommendation')
                    ?
                      <>
                        
                      </>
                    :
                      <>
                        Вы пока не добавили ни одного произведения в свою библиотеку.
                        Перейдите в поиск <img class="inline max-w-6 max-h-6" src="/add_icon.png" /> и добавьте произведения.
                      </>
                  :
                    <>
                      Пользователь пока не добавил ни одного произведения в свою библиотеку.
                    </>
                :
                  (myUser && effectiveUserId() === myUser.myUserInfo.myUserId)
                  ?                    
                    eventType.includes('recommendation')
                    ?
                      <>
                        No personal recommendations yet.
                      </>
                    :
                      <>
                        You have not added any arts yet to your library.
                        Go to search <img class="inline max-w-6 max-h-6" src="/add_icon.png" /> and add arts.
                      </>                    
                  :
                    <>
                      The user has not added any arts yet to their library.
                    </>
                }
              </div>
            }

            {
            (finishedEvents && finishedEvents.length > 0 || artNameTerm.length > 0 || eventType.includes('recommendation')) &&
            <div class={"mt-3 bg-gray-100 p-2 rounded-md border-gray-300 border-dotted left-0 w-full z-50 justify-center" + (myUser ? " bottom-20" : " bottom-0")}>

              <div class="flex justify-center items-center space-x-4">
                {
                eventType === "like" &&
                <ShareButton
                  url={
                    window.location.href
                  }
                  text={
                    ""
                  }
                  overrideButtonText={
                    (myUser && effectiveUserId() === myUser.myUserInfo.myUserId)
                    ?
                      language === "ru" ? "Поделиться своей подборкой" : "Share my selection"
                    :
                      language === "ru" ? "Поделиться подборкой пользователя" : "Share selection of this page"
                  }
                />
                }

                {
                myUser && effectiveUserId() === myUser.myUserInfo.myUserId &&
                  (
                    eventType.includes('recommendation')
                    &&
                      <div>
                        <span class="font-bold">
                          {language === "ru" ? "Персональные рекомендации" : "Personal recommendations"}
                        </span>
                        <div class="flex justify-center items-center text-center space-x-4">
                          <Link to={`/users/${effectiveUserId()}/recommendationsIncoming`}>
                            <span class={"text-blue-500 hover:text-blue-800" + (eventType === "recommendationIncoming" ? " underline" : "")}>
                              {language === "ru" ? "Мне" : "To me"} 
                            </span>
                          </Link>
                          <Link to={`/users/${effectiveUserId()}/recommendationsOutgoing`}> 
                            <span class={"text-blue-500 hover:text-blue-800" + (eventType === "recommendationOutgoing" ? " underline" : "")}>
                              {language === "ru" ? "От меня" : "From me"} 
                            </span>
                          </Link>  
                        </div>
                      </div>
                  )                
                }
              </div>

              <div class="flex my-1 px-4 py-2 bg-white rounded-xl border-2 border-blue-500 overflow-hidden max-w-md mx-auto font-[sans-serif]">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.904 192.904" width="16px"
                  class="fill-gray-600 mr-3 rotate-120">
                  <path
                    d="m190.707 180.101-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z">
                  </path>
                </svg>
                <input ref={searchInputRef} type="search" placeholder={language === "ru" ? "Поиск, напр. 'Игра престолов'" : "Search, e.g. 'Game of thrones'" } class="w-full outline-none text-gray-600 text-lg bg-white"
                  onChange={
                    (e) => {
                      const eventsFetchUrl = `/artEvents/${effectiveUserId()}/${eventType}?${window.location.search}&artNameTerm=${e.target.value}`;
                      fetchFinishedEvents(eventsFetchUrl, true);

                      setArtNameTerm(e.target.value); // to intercept in tags selection
                    }
                  }
                  placeholder={language === "ru" ? ( (!myUser || effectiveUserId() !== myUser.myUserInfo.myUserId) ? "Поиск в библиотеке пользователя" : "Поиск в моей библиотеке") : ( (!myUser || effectiveUserId() !== myUser.myUserInfo.myUserId) ? "Search in user's library" : "Search in my library")}
                />
                {
                (isMobileDeviceExceptAndroid() || isFirefox()) && artNameTerm &&
                  <span class="hover:cursor-pointer text-lg" onClick={()=>{
                      setArtNameTerm(''); // is it really neede if there's update via ref?
                      searchInputRef.current.value = '';
                      searchInputRef.current.focus();
                    }
                  }>
                    &times;
                  </span>
                }
              </div>


              <div class="">
                <SelectableTags language={language} relevantTags={relevantTags !== null ? relevantTags : []} alreadySelectedTags={ relevantTags ? relevantTags.filter((t) => alreadySelectedTagIds.includes(t.tag.id)) : []} onSelectionChange={(tags) => {
                  const tagParams = tags.map((t) => { return "tags="+t.tag.id; }).join("&");

                  const url = 'https://wowcontent.lol';
                  // const url = 'http://localhost:8080'
                  let urlEventType = "likes";
                  if (eventType === "recommendationIncoming") {
                    urlEventType = "recommendationsIncoming";
                  } else if (eventType === "recommendationOutgoing") {
                    urlEventType = "recommendationsOutgoing";
                  }
                  window.history.pushState({}, '', `${url}/users/${curUser.id}/${urlEventType}${ tagParams ? "?"+tagParams : "" }`);

                  const eventsFetchUrl = `/artEvents/${effectiveUserId()}/${eventType}?${tagParams}&artNameTerm=${artNameTerm}`;
                  fetchFinishedEvents(eventsFetchUrl, true);

                  const tagIds = tags.map((t) => { return t.tag.id; });
                  fetchRelevantTags(tagIds);
                }} />
              </div>
            </div>
            }

            <div class="flex-grow overflow-y-auto mb-16">              
              <div>
                <ul className="container mx-auto divide-y divide-gray-200">
                  {finishedEvents.map(
                    (artEvent, index) => {
                      if (index === finishedEvents.length - 1) {              
                        return (
                          <li ref={lastObjectRef}>
                            <CardEventArtChat key={artEvent.event.id} userInfo={{name: artEvent.eventOwnerUserName, avatarUrl: artEvent.eventOwnerUserAvatarUrl}} event={artEvent.event} chatInfo={artEvent.chat} eventUserchatInfo={artEvent.chat} eventType={eventType} art={artEvent.art} language={language} />
                          </li>
                        )
                      } else {
                        return (
                          <li class="mb-4">
                            <CardEventArtChat key={artEvent.event.id} userInfo={{name: artEvent.eventOwnerUserName, avatarUrl: artEvent.eventOwnerUserAvatarUrl}} event={artEvent.event} chatInfo={artEvent.chat} eventType={eventType} art={artEvent.art} language={language} />
                          </li>
                        )
                      }
                    }
                  )}
                </ul>
              </div>
            </div>
          </>
      }
      
    </div>
  );
}

export default PersonalArtEvents;