import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Picker from "emoji-picker-react";
import axiosInstance, {baseWebSocketURL} from '../axiosInstance.js';
import {localizeKey, localizeArt} from './../localize.js';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  Avatar
} from "@chatscope/chat-ui-kit-react";

const MyChatComponent = ({ chatId, language, myUserInfo, userChatInfo, chatOwnerInfo }) => {
  const [messages, setMessages] = useState([]);
  const [typingNick, setTypingNick] = useState(null);
  const typingClearTimeoutRef = useRef(null);
  const [messageText, setMessageText] = useState("");
  const [m_userChatInfo, m_setUserChatInfo] = useState(userChatInfo);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleEmojiClick = (emojiObject) => {
    // const cursorPosition = inputRef.current.selectionStart;
    // setMessageText((prevInput) => prevInput + emojiObject.emoji); // Append emoji to the input
    // setShowEmojiPicker(false); // Close emoji picker

    const cursorPosition = messageText.length; // inputRef.current.selectionStart; // Current cursor position
    const textBeforeCursor = messageText.slice(0, cursorPosition); // Text before cursor
    const textAfterCursor = messageText.slice(cursorPosition); // Text after cursor

    // Insert emoji at the cursor position
    const newValue = textBeforeCursor + emojiObject.emoji + textAfterCursor;
    // console.log("newValue: " + newValue);
    setMessageText(newValue);

    // Update cursor position after emoji insertion
    setTimeout(() => {
      inputRef.current.selectionStart = inputRef.current.selectionEnd =
        cursorPosition + emojiObject.emoji.length;
    }, 0);

    setShowEmojiPicker(false); // Close emoji picker
  };

  // const [ws, setWs] = useState(null);
  const ws = useRef(null);

  const navigate = useNavigate();

  const fetchMessages = () => {
    axiosInstance
    .get('/messages?chatId='+chatId+"&chatOwnerId="+chatOwnerInfo.id)
    .then((response) => {
      // Update the state with the fetched messages
      // console.log(response.data);

      setMessages(response.data.reverse());
    })
    .catch(error => {
      if (error.response.status === 401) {
        // It's ok, non-logged in.
      } else {
        console.error('Error fetching messages:', error);
      }
    });
  };

  // Returns false if web socket was already initialized.
  const initWebSocket = () => {
    if (ws.current && (ws.current.readyState === WebSocket.OPEN || ws.current.readyState === WebSocket.CONNECTING)) {
      return false;
    }

    // Open WebSocket connection when component gains focus and if it's not already open
    ws.current = new WebSocket(`${baseWebSocketURL}/chat/${chatId}`);
    ws.current.onopen = () => {
      // console.log('WebSocket connection opened');
      // You can perform additional actions here, such as sending initial messages
    };
    ws.current.onmessage = (event) => {
      if (event.data.startsWith('_')) {
        const nick = event.data.substring(1);
        if (nick === myUserInfo.myUserEmail.substring(0, myUserInfo.myUserEmail.indexOf('@'))) { // it's me, refactor it
          return;
        }
        setTypingNick(nick);
        clearTimeout(typingClearTimeoutRef.current);
        typingClearTimeoutRef.current = setTimeout(()=>{
          setTypingNick(null);
        }, 3000);
      }

      fetchMessages();
    };
    // Event listener for when the connection is closed
    ws.current.onclose = () => {
      // console.log('WebSocket connection closed');
    };
    // Event listener for errors
    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return true;
  }

  const initWebSocketAndFetchMsgs = () => {
    let needFetch = true; // default, for non-auth-ed
    if (myUserInfo) { // auth-ed
      const initializedNow = initWebSocket();
      needFetch = initializedNow;
    }

    if (needFetch) {
      // Fetch what was missing while web socket was closed
      fetchMessages();
    }
  }

  useEffect(() => {
    if (!myUserInfo) {
      return;
    }

    initWebSocketAndFetchMsgs();
    const intervalChatAndWebSocketUpdate = setInterval(() => {
      initWebSocketAndFetchMsgs();
    }, 3000);

    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => {
      // Clear interval WebSocket update when component unmounts
      clearInterval(intervalChatAndWebSocketUpdate);
      // Close WebSocket connection when component unmounts
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.close();
      }
    };
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const searchUsers = async (username) => {
    axiosInstance.get(`/searchUsers?userName=${username}`)
    .then((response) => {
      setSuggestedUsers(response.data);
    })
    .catch((error) => {
      console.error(error);
    })    
  };

  const inputRef = useRef(null);

  function stripHtml(html) {
    return html.replace(/<[^>]*>?/gm, '');
    // return html.replace(/<[^>]*>?|&nbsp;/gm, function(match) { // also, convert &nbsp; to normal space (for some reason, chatscopre inserts &nbsp in input)
    //     return match === '&nbsp;' ? ' ' : '';
    // });
  }

  const userNamePattern = /@[a-zA-Z0-9_.-]+|\@[(][^()]+[)]/g; // wtf after |

  function isNewStringSymbolAdded(prev, newStr) {
    // Check if the new string is exactly one character longer
    if (newStr.length !== prev.length + 1) {
        return false;
    }

    let i = 0;
    for (; i < prev.length; i++) {
        if (prev[i] === newStr[i]) {
          continue;
        }
        break;
    }
    if (newStr[i] !== '@') {
      return false;
    }
    i++;
    for (; i < newStr.length; i++) {
      if (newStr[i] !== prev[i-1]) {
        return false;
      }
    }
    return true;
  }
  
  const getAffectedUserName = (prevMessageText, newMessageText) => {
    prevMessageText = convertNbspToSpace(prevMessageText);
    newMessageText = convertNbspToSpace(newMessageText);

    if (isNewStringSymbolAdded(prevMessageText, newMessageText)) {
      return "@";
    }

    // Function to extract all @userName patterns
    const extractUserNames = (text) => {
        return text.match(userNamePattern) || [];
    };

    // Extract usernames from previous and new message texts
    const prevUserNames = extractUserNames(prevMessageText);
    const newUserNames = extractUserNames(newMessageText);

    // Determine if a username was added, removed, or altered
    const affectedUserName = newUserNames.find(userName => !prevUserNames.includes(userName)) || 
                             prevUserNames.find(userName => !newUserNames.includes(userName));
                             
    return affectedUserName || '';
  }

  // const getAffectedUserName = (prevMessageText, newMessageText) => {
  //   // Function to extract all @userName patterns
  //   const extractUserNames = (text) => {
  //       const userNamePattern = /@\w+|\@[(][^()]+[)]/g;
  //       return text.match(userNamePattern) || [];
  //   };

  //   // Extract usernames from previous and new message texts
  //   const prevUserNames = extractUserNames(prevMessageText);
  //   const newUserNames = extractUserNames(newMessageText);

  //   // Determine the character added or removed
  //   let addedChar = '';
  //   let affectedUserName = null;

  //   for (let i = 0; i < newMessageText.length; i++) {
  //       if (prevMessageText[i] !== newMessageText[i]) {
  //           addedChar = newMessageText[i];
  //           break;
  //       }
  //   }

  //   if (addedChar === '@') {
  //       return '@';
  //   }

  //   // Determine if a username was added, removed, or altered
  //   affectedUserName = newUserNames.find(userName => !prevUserNames.includes(userName)) || 
  //                      prevUserNames.find(userName => !newUserNames.includes(userName));

  //   return affectedUserName || null;
  // }

  const stripUserNameSuffix = (str) => {
    const userNamePatternSuffixPattern = /@[a-zA-Z0-9_.-]*$/;
    return str.replace(userNamePatternSuffixPattern, '');
  }

  function convertNbspToSpace(text) { // needed because chatscope inserts &nbsp; instead of normal spaces unexpectedly!
    return text.replace(/&nbsp;/g, ' ');
  }
  const handleTyping = (_messageText) => {
    let prevMessageText = messageText;
    let newMessageText = _messageText;

    // // prevMessageText = convertNbspToSpace(prevMessageText);
    // // newMessageText = convertNbspToSpace(newMessageText);
    
    const affectedUserName = getAffectedUserName(prevMessageText, newMessageText);
    if (affectedUserName.length >= 1) {
      searchUsers(affectedUserName.substring(1));  
    } else {
      setSuggestedUsers([]);
    }

    // // _messageText = stripHtml(_messageText);
    // setMessageText(mentionify(_messageText));
    // {
    //   // Search users by name.
    //   const regex = /@\w+/g;
    //   const matches = _messageText.match(regex);
    //   if (matches) {
    //     for (const match of matches) {        
    //       searchUsers(match.substring(1));
    //     }
    //   }
    // }
    setMessageText(_messageText);

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      const nick = myUserInfo.myUserEmail.substring(0, myUserInfo.myUserEmail.indexOf('@'))
      ws.current.send('_'+nick);
    } else {
      console.error('WebSocket connection not open');
    }
  };

  const handleMessageSend = (messageText) => {
    // Just for testing. Messages are already sent to http endpoint below. But maybe it should be done through the same ws-connection.
    // if (ws && ws.readyState === WebSocket.OPEN) {
    //   ws.send(messageText);
    // } else {
    //   console.error('WebSocket connection not open');
    // }
    if (messageText.length > 3000) {
      alert("Message can't be longer than 3000 symbols");
      setMessageText(messageText);
      return;
    }

    if (m_userChatInfo.participates === false) {
      handeSubscribe();
    }

    axiosInstance
    .post('/messages?chatId='+chatId+"&chatOwnerId="+chatOwnerInfo.id, { text: messageText, chatId: chatId, chatOwnerType: chatOwnerInfo.type, chatOwnerId: chatOwnerInfo.id })
    .then((response) => {
      // console.log("before: " + JSON.stringify(messages, null, 2));
      setMessages(prevMessages => [...prevMessages, response.data]);
      // console.log("after: " + JSON.stringify(messages, null, 2));
    })
    .catch((error) => {
      // /setError(error.message); // Set error state with error message
    })
    .finally(() => {
      setMessageText("");
    });
  };

  const handeSubscribe = () => {
    axiosInstance
    .post('/chats/'+chatId+"/participation", {})
    .then((response) => {
      // m_setUserChatInfo({participates: true});
      m_setUserChatInfo(prevState => ({
        ...prevState,
        participates: true
      }));
    })
    .catch((error) => {
      console.error(error);
    });
  };
  const handeUnsubscribe = () => {
    axiosInstance
    .delete('/chats/'+chatId+"/participation")
    .then((response) => {
      // m_setUserChatInfo({participates: false});
      m_setUserChatInfo(prevState => ({
        ...prevState,
        participates: false
      }));
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent) || window.innerWidth <= 768;

  const linkify = (text) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlPattern, '<a class="text-blue-800" href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
  };
  const mentionify = (text) => {
    const mentionPattern = /@([a-zA-Z0-9_.-]+)/g;
    return text.replace(mentionPattern, (match, userName) => {
      return `<span class="text-blue-800">@${userName}</span>`;        
    });
  };
  function linkifyAndMentionify(text) {
    return mentionify(linkify(text));
  }

  // console.log("messages: " + JSON.stringify(messages, null, 2))
  // console.log("myUserID: " + myUserID);

  return (
    <div class="h-80 sm:h-96">
      <div class="h-72 sm:h-80">
        <MainContainer>
          <ChatContainer>
              <MessageList
                typingIndicator={typingNick !== null && <TypingIndicator content={typingNick + " is typing"} />}
              >
              {
              myUserInfo ?
                messages.map((message, index) => (
                  <Message
                    avatarSpacer={!(index === 0 || messages[index-1].fromUserId !== message.fromUserId)}
                    avatarPosition={ myUserInfo && message.fromUserId === myUserInfo.myUserId ? "tr" : "tl" }
                    model={{
                      message: linkifyAndMentionify(message.text),
                      sender: message.fromUserId,
                      direction: myUserInfo && message.fromUserId === myUserInfo.myUserId ? "outgoing" : "incoming",
                      position: "single"
                    }}
                  >
                    {
                      (index === 0 || messages[index-1].fromUserId !== message.fromUserId) &&
                        <Avatar
                          name={message.fromUserName}
                          src={message.fromUserAvatarUrl}
                          onClick={()=>{
                            navigate("/users/"+message.fromUserId+"/likes");
                          }}
                        /> 
                    }
                    <Message.Footer 
                      sender={
                        message.fromUserName !== '' ? message.fromUserName : message.fromUserEmail.substring(0, message.fromUserEmail.indexOf('@')) 
                      }
                      sentTime={
                        new Date(message.sentTime).toLocaleDateString(language, { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })
                      }
                    />
                  </Message>
                ))
              :
                <Message
                  model={{
                    message: language === "ru" ? "Залогиньтесь, чтобы участвовать в чатах" : "Log in to participate in chats",
                    // sender: message.fromUserId,
                    direction: "incoming",
                    position: "single"
                  }}
                >                  
                </Message>
              }
            </MessageList>
            {
              myUserInfo && 
              <MessageInput 
                ref={inputRef} 
                placeholder={localizeKey('typeYourMessageHere', language)} 
                disableMarkdown={true} 
                attachDisabled={true}
                sendOnReturnDisabled={isMobile}
                value={messageText} 
                onChange={(val) => handleTyping(val)}               
                onSend={(textContent) => handleMessageSend(textContent)} 
              />  
            }
          </ChatContainer>
        </MainContainer>
        {
        suggestedUsers.length >= 1 &&
        <div>
          <div style={{'margin-top': -47-suggestedUsers.length*30 + 'px'}}>
          {
            suggestedUsers.map((su, index) => (
              <div style={{'height': '30px'}} class="flex relative z-50 bg-sky-100 items-center" onClick={() => {
                setMessageText(prev =>
                    stripUserNameSuffix(prev) + "@"+ su.userName + "  "
                );
                inputRef.current.focus();
                setSuggestedUsers([]);
              }}> 
                <img src={su.avatarUrl} class="ml-1 w-4 h-4" /> &nbsp;
                {suggestedUsers[index].userName} 
              </div>    
            ))
          }
          </div>
        </div>
        }

        {
        myUserInfo &&
          <div class="relative z-50 flex items-start">
            <div
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              class={"ml-2 text-3xl hover:cursor-pointer" + (suggestedUsers.length == 0 ? " -mt-11" : " mt-0.5") /*what an ugly hack*/}
            >
              😊
            </div>

            {showEmojiPicker && (
              <Picker autoFocusSearch={false} reactionsDefaultOpen={true} onEmojiClick={handleEmojiClick} />
            )}
          </div>
        }
      </div>
      {
      myUserInfo &&
        <>
          {m_userChatInfo.participates ?
            <div class="flex items-center justify-center pt-1 pb-1.5 border border-gray-300 border-t-0 hover:cursor-pointer text-blue-400" onClick={handeUnsubscribe}>
              {localizeKey('unsubscribe', language)}
            </div> :
            <div class="flex items-center justify-center pt-1 pb-1.5 border border-gray-300 border-t-0 hover:cursor-pointer text-blue-400" onClick={handeSubscribe}>
              {localizeKey('subscribe', language)}
            </div>
          }
        </>
      }
      
    </div>
  );
};

export default MyChatComponent;
