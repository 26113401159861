import React, { useState, useEffect } from "react";

const SelectableTags = ({ relevantTags, alreadySelectedTags = [], onSelectionChange, language }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  // WHY DO I NEED THIS TO MAKE selectedTags HAVE INIT VAL?
  useEffect(() => {
    setSelectedTags(alreadySelectedTags)
  });

  const toggleTagSelection = (selectedTag) => {
    let updatedSelection;
    if (selectedTags.find((t) => t.tag.name === selectedTag.tag.name)) {
      // Unselecting the tag
      updatedSelection = selectedTags.filter((t) => t.tag.name !== selectedTag.tag.name);
    } else {
      // Selecting the tag
      updatedSelection = [...selectedTags, selectedTag];
    }
    setSelectedTags(updatedSelection);
    onSelectionChange(updatedSelection); // Call the callback with the updated selection
  };

  // It should be handled in backend.
  const isManualTag = (name) => {
    if (name === "like" || name === "todo" || name === "finished" || name === "veryGood" || name === "good" || name === "bad") {
      return true;
    }
    return false;
  }

  const tagName = (name) => {
    const mapping = {
      'en': {
        'like': 'favorite',
        'veryGood': 'very good',
        'good': 'good',
        'todo': 'todo',
        'finished': 'checked out',
        'bad': 'bad',

        'book': '📖',
        'movie': '🎥',
        'game': '🎮',
        'youtubechannel': 'youtube'
      },
      'ru': {
        'like': 'любимое',
        'veryGood': 'очень понравилось',
        'good': 'понравилось',
        'todo': 'планы',
        'finished': 'ознакомлен(а)',
        'bad': 'не понравилось',

        'book': '📖',
        'movie': '🎥',
        'game': '🎮',
        'youtubechannel': 'youtube'
      }
    }
    const val = mapping[language][name];
    if (val === undefined) {
      return name;
    } else {
      return val;
    }
  }

  const tagStyle = (tag) => {
    const { name, creatorUser } = tag;

    if (creatorUser) {
      return "bg-gray-200 text-gray-700";
    }

    // Global tags below.

    if (isManualTag(name)) {
      if (name === "like") {
        return "bg-red-500/80 text-gray-700";
      } else if (name === "veryGood") {
        return "bg-orange-500/80 text-gray-700";
      } else if (name === "good") {
        return "bg-green-500/80 text-gray-700";
      } else if (name === "todo") {
        return "bg-blue-200/50 text-gray-700";
      } else if (name === "finished") {
        return "bg-yellow-200/50 text-gray-700";
      } else if (name === "bad") {
        return "bg-yellow-700/50 text-gray-700";
      }
    }

    // category tag: book, movie, game etc.
    return "bg-green-200/30 text-gray-700";
  }

  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div className={`flex flex-wrap ${expanded ? "" : "max-h-28 overflow-hidden"}`}>
        {relevantTags.map((tagObject, index) => {
        const { name, creatorUser } = tagObject.tag;
          const { count } = tagObject;

          const tagStyleUnselected = tagStyle(tagObject.tag);

          return (
            <div
              key={index}
              className={`mr-2 mb-1 cursor-pointer px-2 py-1 rounded whitespace-nowrap ${
                selectedTags.find((t) => t.tag.name === name)
                  ? "bg-blue-600 text-white"
                  : tagStyleUnselected
              }`}
              onClick={() => toggleTagSelection(tagObject)}
            >
            {
            tagObject.tag.imgUrl !== ""
            ?
              <div class="flex items-center text-center max-h-6">
                #
                <img class="w-5 max-w-none h-5 rounded-full" src={tagObject.tag.imgUrl} />
                {tagName(name)}
                <span className="text-sm text-gray-500">({count})</span>
              </div>
            :
              <>
                {"#"+tagName(name)}
                <span className="text-sm text-gray-500">({count})</span>
              </>
            }
            </div>
          );
        })}
      </div>
      <div class="flex justify-center">
        <button onClick={() => setExpanded(!expanded)} className="mt-2 px-2 py-1 bg-gray-800 text-white rounded">
          {expanded ? (language === "ru" ? "Свернуть" : "Collapse" ) : (language === "ru" ? "Развернуть" : "Expand" )}
        </button>
      </div>
    </div>
  );
};

export default SelectableTags;